import React from 'react'
import styled from 'styled-components'

import { Section, Text, Title } from '../../components/Core'

import { Container } from 'react-bootstrap'
import { device } from '../../utils'
import { Link } from 'gatsby'

import {RichText, Date} from 'prismic-reactjs';

const StyledSection = styled(Section)`
  text-align: center;
  font-family: 'Poppins';

  .date {
    text-transform: uppercase;
    color: #7295a7;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .title {
    font-family: 'Vijaya';

    padding-top: 5px;
    font-size: 40px;
    line-height: 50px;
    font-weight: 200;
  }

  .copy {
    padding-top: 20px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 200;
  }

  .copy p:after {
    white-space: pre;
  }

  .copy p{
    min-height: 20px;
  }

  display: flex;

  justify-content: center;

  .contact {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    padding-top: 3rem;
  }

  .cbox{
    max-width: 800px;

  }
  @media ${device.sm} {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    .contact {
      display: none;
    }
    .date {
      font-size: 20px;
      line-height: 25px;
    }
    .title {
      font-size: 30px;
      line-height: 35px;
    }

    .copy {
      padding-top: 10px;
      font-size: 20px;
      line-height: 25px;
    }
  }
`

const BlogPost = ({ date, title, copy }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection className="position-relative" css={{ paddingTop: '2rem' }}>
          <div className='cbox'>
          
          <Text className="date">{date}</Text>

          <Title className="title">{title}</Title>

          <Text className="copy">
          {RichText.render(copy)}
          </Text>

          {/* <Link to="/contact"> <Text className="contact">CONTACT US</Text> </Link>*/}
        

          </div>
      </StyledSection>
    </>
  )
}

export default BlogPost
