import React from 'react'
import styled from 'styled-components'

import { Section } from '../../components/Core'
import earBg from '../../assets/ear/living_room.png'
import { device } from '../../utils'

const StyledSection = styled(Section)`
  background-image: url(${earBg});
  background-position: center;
  background-size: cover;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  min-height: 200px;
  margin-top: 75px;

  @media ${device.md}{
    min-height: 500px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 160px;
  }
`

const Hero = ({image}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection hero className="position-relative" css={{backgroundImage: `url(${image})`}}></StyledSection>
    </>
  )
}

export default Hero
