import React from 'react'
import { Element } from 'react-scroll'

import PageWrapper from '../components/PageWrapper'

import Hero from '../sections/landing1/BlogHero'
import { Section } from '../components/Core'
import BlogPost from '../sections/landing1/BlogPost'
import { graphql } from "gatsby";
import SEO from '../components/seo'

const BlogPage = ({ data }) => {

  const post = data.prismicBlogPost.data

  return (
    <>
      <PageWrapper darkTheme={false} alwaysStick={true}>
        <SEO article={true} title={post.title.text} description={post.seo_description.text} image={post.hero.url}/>

        <Hero image={post.hero.url}/>
        <BlogPost
          date={post.post_date}
          title={post.title.text}
          copy={post.copy.raw}
        />
      </PageWrapper>
    </>
  )
}
export default BlogPage


export const query = graphql`
    query($id: String) {
      prismicBlogPost(id: {eq: $id}) {
        id
      uid
      data {
        copy {
          raw
        }
        title {
          text
        }
        seo_description {
          text
        }
        seo_title
        hero {
          url
        }  
        post_date(formatString: "DD MMMM, YYYY")
      }
      }
  }
`;
